<template>
  <SVGContent
    id="root"
    url="content/intro.svg"
    @set-background="setBackground"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SVGContent from "@/components/SVGContent.vue";
import "normalize.css";

export default defineComponent({
  name: "App",
  components: {
    SVGContent,
  },
  methods: {
    setBackground(background: string) {
      document.body.style.background = background;
    },
  },
});
</script>

<style>
html,
body {
  overflow: hidden;
  background: black;
}

html,
body,
#app,
#root {
  width: 100%;
  height: 100%;
  cursor: default;
}
</style>
