
import { defineComponent, PropType, ref, watch } from "vue";
import { BoundingBox } from "@/components/SVGContent.vue";

export default defineComponent({
  name: "AudioArea",
  props: {
    definition: {
      type: Object as PropType<AudioAreaDef>,
      required: true,
    },
    bbox: {
      type: Object as PropType<BoundingBox>,
      required: true,
    },
  },
  setup(props) {
    const audio = ref<HTMLAudioElement | null>(null);

    console.debug(`[AUDIOAREA] Initializing ${props.definition.src}...`);
    console.debug(props.definition);

    const MIN_SCALE = 0.02;
    const MIN_VOLUME_MULTIPLIER = 0.33;
    const vol_x = (1 - MIN_VOLUME_MULTIPLIER) / (1 - MIN_SCALE);
    const vol_b = 1 - vol_x;

    const onBBoxChange = () => {
      const x = props.bbox.x + props.bbox.w / 2;
      const y = props.bbox.y + props.bbox.h / 2;
      const distance = Math.sqrt(
        Math.pow(x - props.definition.cx, 2) +
          Math.pow(y - props.definition.cy, 2)
      );

      if (distance < props.definition.radius) {
        if (audio.value!.paused) {
          console.debug(
            `[AUDIOAREA] Entered audio area "${props.definition.src}", starting playback...`
          );
          audio.value!.play();
        }
        const volume =
          (props.definition.radius - distance) / props.definition.radius;
        audio.value!.volume =
          volume * (props.bbox.z < 1 ? props.bbox.z * vol_x + vol_b : 1);
      } else {
        if (!audio.value!.paused) {
          console.debug(
            `[AUDIOAREA] Left audio area "${props.definition.src}", pausing playback...`
          );
          audio.value!.pause();
        }
      }
    };
    watch(props.bbox, onBBoxChange, { deep: true });

    return {
      audio,
    };
  },
});

export interface AudioAreaDef {
  id: string;
  cx: number;
  cy: number;
  radius: number;
  src: string;
}
