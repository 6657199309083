
import { defineComponent } from "vue";
import SVGContent from "@/components/SVGContent.vue";
import "normalize.css";

export default defineComponent({
  name: "App",
  components: {
    SVGContent,
  },
  methods: {
    setBackground(background: string) {
      document.body.style.background = background;
    },
  },
});
