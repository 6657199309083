<template>
  <div class="video-scroll" ref="root" v-if="definition.directions.length > 0">
    <img
      class="visible displayed loaded"
      :src="definition.files[0]"
      :style="{
        top: `${Math.round(definition.top)}px`,
        left: `${Math.round(definition.left)}px`,
        width: isHorizontal ? `${Math.round(definition.width)}px` : 'auto',
        height: isVertical ? `${Math.round(definition.height)}px` : 'auto',
        transform: `rotate(${definition.angle}deg)`,
      }"
    />

    <!--suppress RequiredAttributes -->
    <img
      v-for="(file, idx) in dynamicFiles"
      :key="`${idx}_${file.src}`"
      :data-src="file.src"
      :style="{
        top: `${Math.round(file.top)}px`,
        left: `${Math.round(file.left)}px`,
        width: `${Math.round(definition.width)}px`,
        height: `${Math.round(definition.height)}px`,
        transform: `rotate(${definition.angle}deg)`,
      }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { rotate } from "@/utils";

export default defineComponent({
  name: "VideoScroll",
  props: {
    definition: {
      type: Object as PropType<VideoScrollDef>,
      required: true,
    },
  },
  computed: {
    dynamicFiles(): { top: number; left: number; src: string }[] {
      return this.definition.files.slice(1).map((src: string, idx: number) => {
        const cy =
          this.definition.top +
          (this.isVertical
            ? this.definition.height * (idx + 1) * this.verticalDirection
            : 0);
        const cx =
          this.definition.left +
          (this.isHorizontal
            ? this.definition.width * (idx + 1) * this.horizontalDirection
            : 0);
        const [left, top] = rotate(
          cx,
          cy,
          this.definition.left,
          this.definition.top,
          this.definition.angle
        );
        return { top, left, src };
      });
    },
    isHorizontal(): boolean {
      return this.definition.directions.some(
        (dir: VideoScrollDirection) =>
          dir === VideoScrollDirection.LEFT ||
          dir === VideoScrollDirection.RIGHT
      );
    },
    isVertical(): boolean {
      return this.definition.directions.some(
        (dir: VideoScrollDirection) =>
          dir === VideoScrollDirection.UP || dir === VideoScrollDirection.DOWN
      );
    },
    horizontalDirection(): number {
      return this.definition.directions.includes(VideoScrollDirection.RIGHT)
        ? 1
        : -1;
    },
    verticalDirection(): number {
      return this.definition.directions.includes(VideoScrollDirection.DOWN)
        ? 1
        : -1;
    },
  },
  mounted() {
    const observer = new IntersectionObserver((entries, _) => {
      entries.forEach((entry) => {
        const element = entry.target as HTMLImageElement;
        if (entry.isIntersecting) {
          element.classList.add("visible");
          if (!element.src) {
            console.debug(
              `[VIDEOSCROLL] Intersected, loading ${element.dataset.src}`
            );
            element.src = element.dataset.src!;
            setTimeout(() => {
              element.classList.add("displayed");
            }, 3000);
            element.onload = () => {
              element.classList.add("displayed");
              element.classList.add("loaded");
              if (this.isHorizontal) {
                element.style.height = "auto";
              } else {
                element.style.width = "auto";
              }
            };
          }
        } else {
          element.classList.remove("visible");
        }
      });
    });
    if (this.$refs.root) {
      Array.from((this.$refs.root as Element).children).forEach((el) => {
        observer.observe(el);
      });
    }
  },
});

export enum VideoScrollDirection {
  RIGHT = "right",
  LEFT = "left",
  UP = "up",
  DOWN = "down",
}

export interface VideoScrollDef {
  id: string;
  top: number;
  left: number;
  angle: number;
  width: number;
  height: number;
  directions: VideoScrollDirection[];
  files: string[];
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.video-scroll img {
  position: absolute;
  image-rendering: optimizeSpeed;
  background: grey;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

.video-scroll img.visible {
  visibility: visible !important;
}

.video-scroll img.displayed {
  opacity: 1 !important;
}

.video-scroll img.loaded {
  background: transparent !important;
}
</style>
